import React, {useEffect, useState} from 'react';
import Chip from "components/chip/chip";
import {DocumentIcon, Signer} from "assets/icons";
import {dateWordShort} from "helper/helper";
import UseContext from "hooks/useContext";
import UseModal from "components/modal/useModal";
import {useNavigate} from "react-router-dom";
import useSlideOver from "../../SlideOver/useSlideOver";


const Tbodyhours = ({champsBody}) => {
    const {toggleSlideOver} = useSlideOver();
    const {setObjID, roleUser, setContent} = UseContext()
    const {toggle} = UseModal()
    const navigate = useNavigate()
    const rUser = localStorage.getItem('rUser')

    const handleModal=(e,i,item)=>{
        e.preventDefault()
        setObjID(i)
        setContent({
            title: item.title,
            idMission: item.id_mission_reel,
            nameCompany: item.name_commercial
        })
        navigate(`../ficheHour/${i}`)
    }

    const handleModalConsult=(e,i,item)=>{
        e.preventDefault()
        setObjID(i)
        setContent({
            title: item.title,
            idMission: item.id_mission_reel,
            nameCompany: item.name_commercial
        })
        navigate(`../ficheHour/${i}`)
    }

    const openTempWorkerInfo = (id) => {
        setObjID(id);
        toggleSlideOver('get_temp_worker');
    };

    return (
        <>
            {champsBody?.map((item, key) =>
                <tr key={key} className={'border-b border-gray-100'}>
                    <td className={'py-5 px-2'}>{item.id_time_sheet}</td>
                    <td className={'py-5 px-2'}>{item.name_commercial}</td>
                    <td className={'py-5 px-2'}>{item.title}</td>
                    <td className={'py-5 px-2'}>{item.id_mission_reel}</td>
                    <td className={'py-5 px-2 cursor-pointer hover:bg-gray-50'} onClick={() => openTempWorkerInfo(item.id_user)}>{item.firstname} {item.lastname}</td>
                    <td className={'py-5 px-2'}>{dateWordShort(item.periode_start)} > {dateWordShort(item.periode_end)}</td>
                    {item.validated !== 0 && (
                        <td className={'py-5 px-2 flex items-center justify-center cursor-pointer'}>
                            {
                                item.validated === 1 || item.validated === 2 ?
                                    <span onClick={(e) => {handleModal(e,item._id, item)}}>
                                    <Signer wh={24}/>
                                </span>
                                    :
                                    <span onClick={(e) => {handleModalConsult(e,item._id, item)}}>
                                    <DocumentIcon wh={24} color={'#111827'}/>
                                </span>
                            }
                        </td>
                    )}
                </tr>
            )}
        </>
    );
};

export default Tbodyhours;